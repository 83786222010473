import React from "react"
import Layout from "../components/Layout"
import Pagetitle from "../components/Pagetitle"
import Seo from "../components/Seo"
import ContactForm from "../components/ContactForm"

const ContactPage = () => (
  <Layout>
    <Seo
      title="Contact us"
      description="Do you have questions about upfeed or experiencing issues with the app. Please contact us and we'll get back to you within 48 hours."
    />
    <div className="container">
      <Pagetitle>Contact Us</Pagetitle>
      <div className={`row justify-content-center`}>
        <div className={`col-lg-7 col-md-10`}>
          <p className="text-center">
            Please reach out with any questions, we're always happy to help.
          </p>
          <ContactForm />
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
