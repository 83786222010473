import React, { useEffect, useState } from "react"

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    website: "",
    message: "",
  })
  const [formStatus, setformStatus] = useState({
    success: false,
    error: false,
  })

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => {
        setformStatus({ success: true, error: false })
      })
      .then(() => {
        const clearFormfields = setTimeout(() => {
          setFormData({
            name: "",
            email: "",
            company: "",
            website: "",
            message: "",
          })
        }, 1000)
        return () => clearFormfields(timer)
      })
      .catch((error) => {
        setformStatus({ ...formStatus, error: true })
      })

    e.preventDefault()
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setformStatus({ success: false, error: false })
    }, 8000)
    return () => clearTimeout(timer)
  }, [formStatus])

  return (
    <>
      {formStatus.success && (
        <div class="alert alert-success" role="alert">
          Thank you! Your message has been successfully sent.
        </div>
      )}
      {formStatus.error && (
        <div class="alert alert-warning" role="alert">
          Oops... something went wrong, please try again.
        </div>
      )}
      <form
        onSubmit={(e) => handleSubmit(e)}
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <div className="row mb-3">
          <div className="form-group col-md-6 pr-4">
            <label for="exampleFormControlInput1">Name:*</label>
            <input
              onChange={handleChange}
              name="name"
              type="text"
              className="form-control"
              placeholder="Name"
              required
              value={formData.name}
            />
          </div>
          <div className="form-group col-md-6 pr-4">
            <label for="exampleFormControlInput1">Company:</label>
            <input
              onChange={handleChange}
              name="company"
              type="text"
              className="form-control"
              placeholder="Company"
              value={formData.company}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="form-group col-md-6 pr-4">
            <label for="exampleFormControlInput1">Email address:*</label>
            <input
              onChange={handleChange}
              name="email"
              type="email"
              className="form-control"
              placeholder="name@company.com"
              required
              value={formData.email}
            />
          </div>

          <div className="form-group col-md-6 pr-4">
            <label for="exampleFormControlInput1">Website:</label>
            <input
              onChange={handleChange}
              name="website"
              type="text"
              className="form-control"
              placeholder="Website"
              value={formData.website}
            />
          </div>
        </div>
        <div className="form-group pt-2 pb-3 pr-3">
          <label for="exampleFormControlTextarea1">Message</label>
          <textarea
            onChange={handleChange}
            name="message"
            type="text"
            className="form-control"
            rows="5"
            value={formData.message}
          />
        </div>
        <input type="hidden" name="form-name" value="contact" />
        <div className="row text-center">
          <div className="col-12">
            <button type="submit" className="btn btn-primary mb-2">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
